var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"affiliate-adjustment-sidebar"},[_c('div',{staticClass:"affiliate-adjustment-sidebar--content"},[_c('v-form',{ref:"form"},[_c('div',[_c('CRInput',{attrs:{"label":"Adjustment Name","placeholder":"Adjustment Name","disabled":_vm.isModeView,"rules":[(val) => !!val || 'Adjustment Name is Required']},model:{value:(_vm.currentMarkup.name),callback:function ($$v) {_vm.$set(_vm.currentMarkup, "name", $$v)},expression:"currentMarkup.name"}})],1),_c('div',[_c('CRSelect',{attrs:{"disabled":_vm.isModeView,"multiple":"","label":"Day","placeholder":"Select day","item-text":"description","item-value":"day","return-object":"","items":_vm.markupDays},model:{value:(_vm.currentMarkup.markupDays),callback:function ($$v) {_vm.$set(_vm.currentMarkup, "markupDays", $$v)},expression:"currentMarkup.markupDays"}})],1),_c('v-flex',[_c('v-layout',{attrs:{"align-center":""}},[_c('v-flex',{staticStyle:{"padding-right":"5px"}},[_c('CRInput',{attrs:{"label":"Start Date","type":"date","disabled":_vm.isModeView},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('v-flex',{staticStyle:{"padding-left":"5px"}},[_c('CRInput',{attrs:{"label":"End Date","type":"date","disabled":_vm.isModeView,"rules":[
                (val) => _vm.validateCurrentDates() || 'Invalid Date Range',
              ]},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1)],1),(!_vm.doesNotHaveTypes)?_c('v-divider'):_vm._e(),(_vm.isVehicleTypeMarkup)?[_vm._l((_vm.vehicleMarkupDetails),function(markupDetail,markupDetailIndex){return _c('v-flex',{key:`new-markup-details-${markupDetailIndex}-${markupDetail.vehicleTypeId}`},[(markupDetail && (!_vm.doesNotHaveTypes || _vm.isModeAdd))?_c('v-flex',[_c('v-layout',{attrs:{"align-center":""}},[_c('v-flex',{staticStyle:{"padding-right":"5px","flex-shrink":"0"},attrs:{"xs6":""}},[_c('CRSelect',{attrs:{"items":_vm.vehicleTypes,"rules":[(val) => !!val || 'Vehicle Type is Required'],"disabled":!_vm.isModeAdd,"item-text":"label","item-value":"id","label":"Vehicle Type","placeholder":"Select Vehicle Type"},model:{value:(markupDetail.vehicleTypeId),callback:function ($$v) {_vm.$set(markupDetail, "vehicleTypeId", $$v)},expression:"markupDetail.vehicleTypeId"}})],1),_c('v-flex',{attrs:{"xs6":""}},[_c('v-layout',{attrs:{"align-center":""}},[_c('v-flex',{staticStyle:{"padding-left":"5px"}},[_c('CRSelect',{attrs:{"value":_vm.rateTypes,"items":_vm.marketRateTypeOptions,"multiple":"","rules":[
                        (val) => _vm.isNotEmptyArray(val) || 'Type is Required',
                      ],"disabled":!_vm.isModeAdd,"item-text":"label","item-value":"key","label":"Rate Type","placeholder":"Select Rate Type"},on:{"input":(e) => _vm.updateMarketRateTypeRows(e, markupDetailIndex)}})],1),(markupDetailIndex !== 0)?_c('v-flex',{attrs:{"xs1":""}},[_c('v-btn',{staticStyle:{"margin":"0 0 0 5px"},attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.deleteVehicleType(markupDetailIndex)}}},[_c('CRIcon',[_vm._v("trash")])],1)],1):_vm._e()],1)],1)],1)],1):_vm._e(),(
              markupDetail &&
              markupDetail.vehicleTypeId &&
              markupDetail.marketRateTypeRows
            )?_c('div',_vm._l((markupDetail.marketRateTypeRows),function(markupPercentage,markupPercentageIndex){return _c('v-flex',{key:`new-rate-${markupPercentageIndex}-${markupDetail.vehicleTypeIndex}`},[_c('v-layout',{attrs:{"align-center":""}},[_c('v-flex',{staticStyle:{"padding-right":"5px"}},[_c('CRTextField',{attrs:{"label":`${markupPercentage.marketRateTypeLabel.replace(
                      'Rate',
                      ''
                    )} Adjustment %`,"placeholder":"Enter adjustment percentage","rules":[(val) => !!val || 'Adjustment % is Required'],"disabled":_vm.isModeView},model:{value:(markupPercentage.percentage),callback:function ($$v) {_vm.$set(markupPercentage, "percentage", $$v)},expression:"markupPercentage.percentage"}})],1),_c('v-flex',{staticStyle:{"padding-left":"5px","font-size":"14px","font-weight":"600"}},[_c('v-layout',{attrs:{"align-center":""}},[_c('v-flex',[_c('p',[_vm._v("Current Rate")]),_c('p',{staticStyle:{"line-height":"40px","margin-bottom":"8px"}},[_vm._v(" "+_vm._s(_vm.currentRateValue( markupDetail.vehicleTypeId, markupPercentage.marketRateTypeLabel ))+" ")])]),_c('v-flex',[_c('p',[_vm._v("Adjusted Rate")]),_c('p',{staticStyle:{"line-height":"40px","margin-bottom":"8px"}},[_vm._v(" "+_vm._s(_vm.adjustedRateValue( markupPercentage.percentage, markupDetail.vehicleTypeId, markupPercentage.marketRateTypeLabel ))+" ")])])],1)],1)],1)],1)}),1):_vm._e()],1)}),(_vm.isModeAdd)?_c('div',[_c('v-flex',[_c('p',{staticClass:"add-vehicle-type-btn",on:{"click":_vm.addNewVehicleType}},[_vm._v(" + Add Vehicle Type ")])])],1):_vm._e()]:_vm._e()],2)],1),_c('div',{staticClass:"cr-sidebar-dialog--button-spacer"}),(_vm.isModeEdit)?[_c('div',{staticClass:"cr-sidebar-dialog--button-spacer"}),_c('CRButton',{staticClass:"affiliate-adjustment-sidebar--delete-btn",attrs:{"id":"affiliate-adjustment-sidebar-delete-btn","text-color":"red"},on:{"click":_vm.deleteMarkup}},[_vm._v(" Delete ")])]:_vm._e(),(!_vm.isModeView)?[_c('CRButton',{staticClass:"affiliate-adjustment-sidebar--action-btn",attrs:{"id":"affiliate-adjustment-save-btn","loading":_vm.submitting,"color":"primary"},on:{"click":_vm.submit}},[_vm._v(" Save ")])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }