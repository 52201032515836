<template>
  <div class="affiliate-adjustment-sidebar">
    <div class="affiliate-adjustment-sidebar--content">
      <v-form ref="form">
        <div>
          <CRInput
            v-model="currentMarkup.name"
            label="Adjustment Name"
            placeholder="Adjustment Name"
            :disabled="isModeView"
            :rules="[(val) => !!val || 'Adjustment Name is Required']"
          />
        </div>
        <div>
          <CRSelect
            v-model="currentMarkup.markupDays"
            :disabled="isModeView"
            multiple
            label="Day"
            placeholder="Select day"
            item-text="description"
            item-value="day"
            return-object
            :items="markupDays"
          />
        </div>
        <v-flex>
          <v-layout align-center>
            <v-flex style="padding-right: 5px">
              <CRInput
                v-model="startDate"
                label="Start Date"
                type="date"
                :disabled="isModeView"
              />
            </v-flex>
            <v-flex style="padding-left: 5px">
              <CRInput
                v-model="endDate"
                label="End Date"
                type="date"
                :disabled="isModeView"
                :rules="[
                  (val) => validateCurrentDates() || 'Invalid Date Range',
                ]"
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-divider v-if="!doesNotHaveTypes" />
        <template v-if="isVehicleTypeMarkup">
          <v-flex
            v-for="(markupDetail, markupDetailIndex) in vehicleMarkupDetails"
            :key="`new-markup-details-${markupDetailIndex}-${markupDetail.vehicleTypeId}`"
          >
            <v-flex v-if="markupDetail && (!doesNotHaveTypes || isModeAdd)">
              <v-layout align-center>
                <v-flex style="padding-right: 5px; flex-shrink: 0" xs6>
                  <CRSelect
                    v-model="markupDetail.vehicleTypeId"
                    :items="vehicleTypes"
                    :rules="[(val) => !!val || 'Vehicle Type is Required']"
                    :disabled="!isModeAdd"
                    item-text="label"
                    item-value="id"
                    label="Vehicle Type"
                    placeholder="Select Vehicle Type"
                  />
                </v-flex>
                <v-flex xs6>
                  <v-layout align-center>
                    <v-flex style="padding-left: 5px">
                      <CRSelect
                        :value="rateTypes"
                        :items="marketRateTypeOptions"
                        multiple
                        :rules="[
                          (val) => isNotEmptyArray(val) || 'Type is Required',
                        ]"
                        :disabled="!isModeAdd"
                        item-text="label"
                        item-value="key"
                        label="Rate Type"
                        placeholder="Select Rate Type"
                        @input="
                          (e) => updateMarketRateTypeRows(e, markupDetailIndex)
                        "
                      />
                    </v-flex>
                    <v-flex v-if="markupDetailIndex !== 0" xs1>
                      <v-btn
                        color="error"
                        icon
                        style="margin: 0 0 0 5px"
                        @click="deleteVehicleType(markupDetailIndex)"
                      >
                        <CRIcon>trash</CRIcon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <div
              v-if="
                markupDetail &&
                markupDetail.vehicleTypeId &&
                markupDetail.marketRateTypeRows
              "
            >
              <v-flex
                v-for="(markupPercentage,
                markupPercentageIndex) in markupDetail.marketRateTypeRows"
                :key="`new-rate-${markupPercentageIndex}-${markupDetail.vehicleTypeIndex}`"
              >
                <v-layout align-center>
                  <v-flex style="padding-right: 5px">
                    <CRTextField
                      v-model="markupPercentage.percentage"
                      :label="`${markupPercentage.marketRateTypeLabel.replace(
                        'Rate',
                        ''
                      )} Adjustment %`"
                      placeholder="Enter adjustment percentage"
                      :rules="[(val) => !!val || 'Adjustment % is Required']"
                      :disabled="isModeView"
                    />
                  </v-flex>
                  <v-flex
                    style="padding-left: 5px; font-size: 14px; font-weight: 600"
                  >
                    <v-layout align-center>
                      <v-flex>
                        <p>Current Rate</p>
                        <p style="line-height: 40px; margin-bottom: 8px">
                          {{
                            currentRateValue(
                              markupDetail.vehicleTypeId,
                              markupPercentage.marketRateTypeLabel
                            )
                          }}
                        </p>
                      </v-flex>
                      <v-flex>
                        <p>Adjusted Rate</p>
                        <p style="line-height: 40px; margin-bottom: 8px">
                          {{
                            adjustedRateValue(
                              markupPercentage.percentage,
                              markupDetail.vehicleTypeId,
                              markupPercentage.marketRateTypeLabel
                            )
                          }}
                        </p>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </div>
          </v-flex>
          <div v-if="isModeAdd">
            <v-flex>
              <p class="add-vehicle-type-btn" @click="addNewVehicleType">
                + Add Vehicle Type
              </p>
            </v-flex>
          </div>
        </template>
      </v-form>
    </div>
    <div class="cr-sidebar-dialog--button-spacer" />
    <template v-if="isModeEdit">
      <div class="cr-sidebar-dialog--button-spacer" />
      <CRButton
        id="affiliate-adjustment-sidebar-delete-btn"
        class="affiliate-adjustment-sidebar--delete-btn"
        text-color="red"
        @click="deleteMarkup"
      >
        Delete
      </CRButton>
    </template>
    <template v-if="!isModeView">
      <CRButton
        id="affiliate-adjustment-save-btn"
        :loading="submitting"
        class="affiliate-adjustment-sidebar--action-btn"
        color="primary"
        @click="submit"
      >
        Save
      </CRButton>
    </template>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { mapActions } from 'vuex'
import marketRates from '@/services/marketRates'
import markups from '@/services/markups'
import { deepClone } from '@/utils/deepClone'
import { filter } from '@/utils/filter'
import { currencyFilter } from '@/utils/string'
import { isNotEmptyArray } from '@/utils/validators'
import { daysMap } from '@/utils/time'

const MARKUP_TYPE_ID_VEHICLE_TYPE = 7

export default {
  props: {
    mode: {
      type: String,
      default: 'add',
    },
    markup: {
      type: Object,
      default: () => {},
    },
    company: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      vehicleTypes: [],
      markupDays: daysMap,
      vehicleMarkupDetails: [],
      markupRateOptions: [],
      marketRateTypeOptions: [],
      rateTypes: [],
      selectedVehicleTypeId: null,
      startDate: DateTime.local().toISODate(),
      endDate: DateTime.local().toISODate(),
      submitting: false,
      currentMarkup: {
        markupName: null,
        markupDays: [],
        markupTypeId: MARKUP_TYPE_ID_VEHICLE_TYPE,
      },
    }
  },
  computed: {
    isModeAdd() {
      return this.mode === 'add'
    },
    isModeView() {
      return this.mode === 'view'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    doesNotHaveTypes() {
      return (
        !this.currentMarkup.marketRateTypeRows &&
        !this.currentMarkup.vehicleType
      )
    },
    isVehicleTypeMarkup() {
      return this.currentMarkup?.markupTypeId === MARKUP_TYPE_ID_VEHICLE_TYPE
    },
  },
  async mounted() {
    const requests = [
      this.getVehicleTypes(),
      this.getMarketRates(),
      this.getMarketRateTypes(),
    ]
    await Promise.all(requests)

    this.isModeEdit = this.mode === 'edit'
    if (this.isModeEdit || this.isModeView) {
      this.currentMarkup = deepClone(this.markup)
      this.startDate = this.currentMarkup.tripStartDate.split('T')[0]
      this.endDate = this.currentMarkup.tripEndDate.split('T')[0]
      this.rateTypes = [this.currentMarkup.rateType]
      const newMarkupDetail = {
        vehicleTypeId: this.currentMarkup?.vehicleType.id,
        marketRateTypeRows: [
          {
            marketRateTypeKey: this.currentMarkup?.rateType.id,
            marketRateTypeLabel: this.currentMarkup?.rateType?.label,
            percentage: this.currentMarkup.markupPercentage,
          },
        ],
      }
      this.vehicleMarkupDetails = [newMarkupDetail]
    } else {
      this.initiliazeVehicleMarkups()
    }
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    isNotEmptyArray,
    initiliazeVehicleMarkups() {
      this.vehicleMarkupDetails = [
        {
          vehicleTypeId: null,
          marketRateTypeRows: [],
        },
      ]
    },
    async getVehicleTypes() {
      const res = await this.$store.dispatch('vehicles/getVehicleTypes', {
        page: 1,
        pageSize: -1,
      })
      this.vehicleTypes = res.data.resultList
    },
    async getMarketRateTypes() {
      let response
      try {
        response = await this.$store.dispatch('types/getMarketRateTypes')
        const { data } = response
        const includedRateTypes = [
          'daily_rate',
          'hourly_rate',
          'mileage',
          'deadmile',
        ]
        this.marketRateTypeOptions = data.filter((mrt) =>
          includedRateTypes.includes(mrt.key)
        )
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
        return
      }
    },
    validateCurrentDates() {
      return (
        DateTime.fromISO(this.startDate) <= DateTime.fromISO(this.endDate) &&
        DateTime.fromISO(this.endDate) >= DateTime.fromISO(this.startDate)
      )
    },
    async getMarketRates() {
      const filterUtil = filter()

      const marketplaceFilter = {
        column: {
          _t_id: '99e1a575-3d97-4960-9275-e0dc6679b28d',
          prop: 'marketplace',
          filterType: 'eq',
        },
        prop: 1,
      }

      const marketId = this.company?.address?.nearestMarketId || 1
      const marketIdFilter = {
        column: {
          _t_id: 'a72ee0a2-a361-4c2b-8cf0-821e58454723',
          prop: 'marketId',
          filterType: 'eq',
        },
        value: marketId,
      }

      const companyIdFilter = {
        column: {
          _t_id: '6eb77f81-ec0e-4cb4-9e98-b44b6fabe92c',
          prop: 'companyId',
          filterType: 'eq',
        },
        value: this.company.companyId,
      }

      for (const marketRateFilter of [
        marketplaceFilter,
        marketIdFilter,
        companyIdFilter,
      ]) {
        const parent = filterUtil.createParent('and')
        filterUtil.add(parent, marketRateFilter)
      }

      const payload = {
        pageSize: -1,
        page: 1,
        filters: filterUtil.asQueryParams(),
        additionalQueries: `isAffiliateDetailsView=true&companyId=${this.company.companyId}`,
      }
      const res = await marketRates.tableView(payload)

      this.markupRateOptions = res.data.resultList
    },
    currentRateValue(vehicleTypeId, marketRateTypeLabel) {
      const marketRate = this.markupRateOptions.find(
        (mr) =>
          mr.marketplace === 1 &&
          mr.vehicleTypeId === vehicleTypeId &&
          mr.marketRateType === marketRateTypeLabel
      )
      if (!marketRate) {
        return '$---.--'
      }
      return currencyFilter(marketRate?.highRate)
    },
    adjustedRateValue(percentage, vehicleTypeId, marketRateTypeLabel) {
      const marketRate = this.markupRateOptions.find(
        (mr) =>
          mr.marketplace === 1 &&
          mr.vehicleTypeId === vehicleTypeId &&
          mr.marketRateType === marketRateTypeLabel
      )
      if (!percentage || !marketRate) {
        return '$---.--'
      }
      const calculatedRate =
        marketRate?.highRate * (parseFloat(percentage) + 100) * 0.01
      return currencyFilter(calculatedRate)
    },
    addNewVehicleType() {
      this.vehicleMarkupDetails.push({
        vehicleTypeId: null,
        marketRateTypeRows: [],
      })
    },
    deleteVehicleType(index) {
      if (index > 0) {
        this.vehicleMarkupDetails.splice(index, 1)
      }
    },
    // Start Submit Methods
    prepareModelForSubmit() {
      this.model = deepClone(this.currentMarkup)
      this.model.markupTypeId = this.markupTypeId
      this.model.customerId = null
      this.model.customerAccountId = null
      this.model.industryId = null
      this.model.eventId = null
      this.model.marketplace = false
      this.model.tripStartDate = this.startDate
      this.model.tripEndDate = this.endDate
      this.model.markupDays = this.markupDays
    },
    async submit() {
      // Casting a ref to `any` prevents a TS error when calling .validate()
      const form = this.$refs['form']

      if (!form.validate()) {
        return
      }

      await this.prepareModelForSubmit()

      const params = {
        id: this.model.markupId,
        payload: this.model,
      }

      if (this.isModeAdd) {
        await Promise.all(
          this.vehicleMarkupDetails.map((nmd) => {
            this.model.vehicleTypeKey = this.vehicleTypes.find(
              (vt) => vt.id === nmd.vehicleTypeId
            ).key
            nmd.marketRateTypeRows.map((mp) => {
              this.model.markupPercentage = parseFloat(mp.percentage)
              this.model.marketRateTypeKey = mp.marketRateTypeKey
            })
            markups.create(params)
          })
        )
      } else {
        this.model.markupPercentage = parseFloat(
          this.vehicleMarkupDetails[0]?.marketRateTypeRows[0]?.percentage
        )
        await markups.update(params)
      }
    },
    async deleteMarkup() {
      if (!this.currentMarkup?.markupId) {
        return
      }
      await markups.delete(this.currentMarkup.markupId)
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
  },
}
</script>
<style lang="scss" scoped>
.affiliate-adjustment-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--delete-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 71px;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }
}
.add-vehicle-type-btn {
  color: $primary;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}
</style>
